.bg-container {
  padding: 0;
  height: 100vh;
  width: 100vw;
  background-image: url('../assets/network-4851079_1920.jpg');
  background-size: cover;
}
.login-container {
  font-family: Arial, Helvetica, sans-serif;
  width: 450px;
  max-width: calc(100vw - 2em);
  max-height: calc(100vh - 2em);
  padding: 1em;
  background-color: rgba(245, 245, 245, 0.8);
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}
.rounded {
  border-radius: 8px !important;
}
/*# sourceMappingURL=login.css.map */